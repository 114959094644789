// Custom scripts


$(document).ready(function () {

    // Toggle menu animation class
    $(".menu-btn").click(function () {
        $(".btn-inner").toggleClass("active");
    });

    // Wow animation library
    // https://github.com/matthieua/WOW/issues/196#issuecomment-348734401
    var scrolled = false;
    $(window).on('scroll', function() {
	    if(!scrolled) {
		    scrolled = true;
		    new WOW().init();
		}
	});

    // Scroll to top button
    var btn = $('#btn-page-top');
    $(window).scroll(function () {
        if ($(window).scrollTop() > 300) {
            btn.addClass('show');
        } else {
            btn.removeClass('show');
        }
    });
    btn.on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({ scrollTop: 0 }, '300');
    });

    // Accessibility: Disable animations when tabbing.
    // https://github.com/matthieua/WOW/issues/330
    $(document).on('keyup', function (e) {
	    // All keys
		$('.wow').removeClass('wow');
    });

    // Accessibility: Skip to content button.
    $(".skip").click(function (event) {

        // strip the leading hash and declare
        // the content we're skipping to
        var skipTo = "#" + this.href.split('#')[1];

        // Setting 'tabindex' to -1 takes an element out of normal
        // tab flow but allows it to be focused via javascript
        $(skipTo).attr('tabindex', -1).on('blur focusout', function () {

            // when focus leaves this element,
            // remove the tabindex attribute
            $(this).removeAttr('tabindex');

        }).focus(); // focus on the content container
    });

	// Autoplay add/remove for modals (YouTube)
	var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

	function YTPostMessage(iframe, func, args) {
		// Adapted from: http://jsfiddle.net/MadLittleMods/3J2wT/
		var message = {
			event: 'command',
			func: func,
			args: (args) ? args : ''
		};

		iframe.contentWindow.postMessage(JSON.stringify(message), '*');
	}

	$('.modal').has('iframe').on('shown.bs.modal', function (e) {
		var autoplay = '?autoplay=1';
		var related_no = '&rel=0';
		var jsAPI = '&enablejsapi=1';
		var $iframe = $(e.target).find('iframe');
		var src = $iframe.attr('src');

		if(src.match(/(youtube|youtu\.be)/g) && !src.match(/\?autoplay=1/g)) {
			$iframe.attr('src', src + autoplay + related_no + jsAPI);
		} else {
			YTPostMessage($iframe[0], 'playVideo');
		}
	});
	$('.modal').has('iframe').on('hide.bs.modal', function (e) {
		var $iframe = $(e.target).find('iframe');
		YTPostMessage($iframe[0], 'pauseVideo');
	});

});

